<template>
  <div v-if="isValid" class="embed-block" v-bind="rootBindings">
    <div class="embed-block__embed embed" :class="embedClass">
      <iframe
        v-if="type == 'video'"
        loading="lazy"
        sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation allow-presentation"
        class="embed__if"
        allowfullscreen
        :src="embedUrl"
      />
      <div v-else-if="type == 'custom'" v-html="code" />
    </div>
  </div>
</template>

<script lang="ts" setup>
const VIDEOS = [
  {
    reg: /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/i,
    url: 'https://www.youtube.com/embed/$5',
    params: {
      'picture-in-picture': 1,
      accelerometer: 1,
      gyroscope: 1,
    },
  },
  {
    reg: /^.*vimeo.com\/(\d+)($|\/|\b)/i,
    url: 'https://player.vimeo.com/video/$1',
    params: {
      title: 0,
      byline: 0,
      portrait: 0,
    },
  },
];

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
  responsive: {
    type: Boolean,
    default: true,
  },
  ratio: {
    type: String,
    default: '4_3',
  },
  code: {
    type: String,
    required: true,
  },
});

const rootBindings = computed(() => ({}));

// const embedStyle = computed(() => {
//   const ratio = props.ratio.split('x').map((r) => Number(r));
//   if (ratio.length < 2) {
//     return null;
//   }
//
//   const paddingTop = (ratio[1] / ratio[0]) * 100;
//
//   return {
//     'padding-top': `${paddingTop}%`,
//   };
// })

const embedClass = computed(() => {
  if (!props.responsive) {
    return 'is-default';
  }

  return ['is-r', `is-${props.ratio}`];
});

const embedUrl = computed(() => {
  if (props.type === 'custom' || !props.code) {
    return null;
  }
  for (let i = 0; i < VIDEOS.length; i++) {
    const v = VIDEOS[i];
    const m = v.reg.exec(props.code);
    if (m) {
      const params = {
        ...v.params,
      };
      const query = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&');
      const and = v.url.includes('?') ? '&' : '?';
      return `${props.code.replace(v.reg, v.url)}${and}${query}`;
    }
  }

  return null;
});

const isValid = computed(() => true);
</script>
