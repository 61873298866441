<template>
  <div>
    <div v-if="isLoading" class="box is-centered">
      <p>Loading...</p>
    </div>
    <form v-show="!isLoading" :id="`mktoForm_${uniqId}`" />
  </div>
</template>

<script lang="ts" setup>
import { useScriptTag } from '@vueuse/core';

const emit = defineEmits(['load', 'success']);

const props = defineProps({
  formId: {
    type: String,
    required: true,
  },
  uniqId: {
    type: [Number, String],
    required: true,
  },
  apiUrl: {
    type: String,
    default: '//info.publictrust.co.nz',
  },
});

const isLoading = ref(true);

const { load } = useScriptTag(
  '//info.publictrust.co.nz/js/forms2/js/forms2.min.js',
  () => {
    initForm();
  },
  {
    manual: true,
  }
);

onMounted(() => {
  if (window.MktoForms2) {
    initForm();
  } else {
    load();
  }
});

const initForm = () => {
  window.MktoForms2.loadForm(props.apiUrl, props.formId, props.uniqId);

  // success handler
  window.MktoForms2.whenReady((form) => {
    cleanupStyles(form);

    isLoading.value = false;
    emit('load', form);

    form.onSuccess((values, followUpUrl) => {
      emit('success', form, values, followUpUrl);
      return false;
    });
  });
};

/**
 * Create a completely barebones, user-styles-only Marketo form by
 * removing inline STYLE attributes and disabling child STYLE elements
 */
const cleanupStyles = (form) => {
  const formEl = form.getFormElem()[0];

  // remove element styles from root and children (may want to disable this while debugging)
  const elsWithStyles = document.querySelectorAll('#' + formEl.id + ', #' + formEl.id + ' [style]');
  for (let i = 0, imax = elsWithStyles.length; i < imax; i++) {
    elsWithStyles[i].removeAttribute('style');
  }

  // disable all Marketo-sourced stylesheets
  const styleSheets = document.styleSheets;
  for (let i = 0, imax = styleSheets.length; i < imax; i++) {
    const ssLoc = document.createElement('A');
    ssLoc.href = styleSheets[i].href;

    if (ssLoc.hostname.search(/\.marketo\.com$/) !== -1 || (styleSheets[i].ownerNode || styleSheets[i].owningElement).parentNode === formEl) {
      //  inline STYLEs within FORM tag
      styleSheets[i].disabled = true;
    }
  }
};
</script>
