import { defineStore } from 'pinia';

export const useTimelineStore = defineStore('timeline', {
  state: () => ({
    currentEvent: null,
    events: [],
  }),
  actions: {
    setCurrentEvent(currentEvent) {
      this.currentEvent = currentEvent;
    },
    setEvents(events) {
      this.events = events;
    },
  },
});
