<template>
  <component :is="tag" class="button-link" v-bind="linkBindings" :class="`is-${type}`">
    <span v-if="label">{{ label }}</span>
    <span v-else-if="$slots.default">
      <slot />
    </span>
  </component>
</template>

<script setup lang="ts">
import { isLinkExternal } from '@/utils/links';
import { slashed, urlify } from '@/utils/url';
import AppLink from '~/components/AppLink';

const props = defineProps({
  type: {
    type: String,
    default: 'primary',
  },
  label: {
    type: String,
    default: null,
  },
  to: {
    type: String,
    required: true,
  },
});
const isExternal = computed(() => isLinkExternal(props.to));

const tag = computed(() => (isExternal.value ? 'a' : AppLink));

const linkBindings = computed(() => {
  const bindings = {};

  if (isExternal.value) {
    bindings.href = urlify(props.to);
  } else {
    bindings.to = slashed(props.to);
  }

  return bindings;
});
</script>
